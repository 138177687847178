window.body = document.body;

let recaptchaPublicKey = body.getAttribute('data-grcpublic');
let tokenFields = document.querySelectorAll('.google-token-field');


document.addEventListener("DOMContentLoaded", function () {
    if($('body').hasClass('FormPage') || $('body').hasClass('EventFormPage')) {
        if (recaptchaPublicKey != null) {
            grecaptcha.ready(function () {
                tokenFields.forEach(function (field) {
                    grecaptcha.execute(recaptchaPublicKey, {action: 'submit'}).then(function (token) {
                        field.value = token;
                    });
                });
            });
        }
    }
});
