let formMessages = document.querySelectorAll('.message');
let activeMessage;
let activeForm;

document.addEventListener("DOMContentLoaded", function() {
    var scrollToForm = false;

    formMessages.forEach(function(item){
        if(window.getComputedStyle(item).display != 'none'){
            scrollToForm = true;
            activeMessage = item;
            activeForm = item.parentElement;
        }
    });

    // navbar muss in helpers.js deklariert werden
    if(scrollToForm){
        smoothScroll(activeForm.id, navbar.offsetHeight + 20);
    }
});
