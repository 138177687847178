$('a[data-receiver]').on('click', (e) => {
    e.preventDefault();
    location.href = "mailto:" + e.currentTarget.dataset.receiver + "@" + e.currentTarget.dataset.domain;
});

$('.linkbox-slider').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 2000,
    prevArrow: $('.linkbox-prev'),
    nextArrow: $('.linkbox-next'),
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
});

$('.kitchenbox-slider').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: $('.kitchenbox-prev'),
    nextArrow: $('.kitchenbox-next'),
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
});

$('.brand-slider').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: $('.brand-prev'),
    nextArrow: $('.brand-next'),
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
});


function mobileOnlyInspirationSlider() {
    $('.inspiration-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        prevArrow: $('.inspiration-prev'),
        nextArrow: $('.inspiration-next'),
    });
}

function mobileOnlyProductSlider() {
    $('.product-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        prevArrow: $('.product-prev'),
        nextArrow: $('.product-next'),
    });
}
if(window.innerWidth < 768) {
    mobileOnlyInspirationSlider();
    mobileOnlyProductSlider();
}


$("[data-paroller-factor]").paroller();

// Select all links with hashes
$('a.scroll-to[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function (event) {
        // On-page links
        if (
            location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
            &&
            location.hostname == this.hostname
        ) {
            // Figure out element to scroll to
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            // Does a scroll target exist?
            if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: target.offset().top
                }, 1500, function () {
                    // Callback after animation
                    // Must change focus!
                    var $target = $(target);
                    $target.focus();
                    if ($target.is(":focus")) { // Checking if the target was focused
                        return false;
                    } else {
                        $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                        $target.focus(); // Set focus again
                    }
                    ;
                });
            }
        }
    });



var vpanimations = $('[data-vpanim="true"]');

$(document).ready(function () {
    $('.kitchenbox-slider .slick-track').addClass('d-flex');
    $('.kitchenbox-slider .slick-slide').addClass('d-flex h-auto');


    $('.nav-button').click(function(){
        $('body').toggleClass('nav-open');
    });

    $.each(vpanimations, function () {
        if ($(this).isInViewport()) {
            if (!$(this).hasClass('animated')) {
                $(this).addClass('animated ' + $(this).data('vpanimeffect') + ' delay-' + $(this).data('vpanimdelay'));
            }
        }
    });

    // scroll body to 0px on click
    $('#back-to-top').click(function () {
        $('#back-to-top').hide();
        $('body,html').animate({
            scrollTop: 0
        }, 1500);
        return false;
    });

    $('#back-to-top').show();

});

$(window).on('resize scroll', function () {

    if ($(this).scrollTop() > 200) {
        $('#back-to-top').fadeIn();
    } else {
        $('#back-to-top').fadeOut();
    }

    $.each(vpanimations, function () {
        if ($(this).isInViewport()) {
            if (!$(this).hasClass('animated')) {
                $(this).addClass('animated ' + $(this).data('vpanimeffect') + ' delay-' + $(this).data('vpanimdelay'));
            }
        } else {
            if ($(this).hasClass('animated')) {
                $(this).removeClass('animated ' + $(this).data('vpanimeffect') + ' delay-' + $(this).data('vpanimdelay'));
            }
        }
    });

    if(window.innerWidth < 768) {
        if(!$('.inspiration-slider').hasClass('slick-initialized')){
            mobileOnlyInspirationSlider();
        }
        if(!$('.product-slider').hasClass('slick-initialized')){
            mobileOnlyProductSlider();
        }

    }else{
        if($('.inspiration-slider').hasClass('slick-initialized')){
            $('.inspiration-slider').slick('unslick');
        }
        if($('.product-slider').hasClass('slick-initialized')){
            $('.product-slider').slick('unslick');
        }
    }

});



$.fn.isInViewport = function() {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
};

$.fn.isFullyInViewport = function() {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementTop >= viewportTop && elementBottom <= viewportBottom;
};
