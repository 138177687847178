const jQuery = require("jquery");
window.jQuery = window.$ = jQuery;

const Cookies = require("js-cookie");
window.Cookies = Cookies;

// ####### BOOTSTRAP #########

const Alert = require("bootstrap/js/dist/alert");
window.Alert = Alert;

const Button = require("bootstrap/js/dist/button");
window.Button = Button;

const Collapse = require("bootstrap/js/dist/collapse");
window.Collapse = Collapse;

const Dropdown = require("bootstrap/js/dist/dropdown");
window.Dropdown = Dropdown;

const Modal = require("bootstrap/js/dist/modal");
window.Modal = Modal;

const Fancybox = require("@fancyapps/ui");
window.Fancybox = Fancybox;

const Popover = require("bootstrap/js/dist/popover")
window.Popover = Popover;

const Carousel = require("bootstrap/js/dist/carousel")
window.Carousel = Carousel;

const Tooltip = require("bootstrap/js/dist/tooltip");
window.Tooltip = Tooltip;

const Paroller = require("paroller.js/dist/jquery.paroller");
window.Paroller = Paroller;

// //import 'bootstrap/js/dist/offcanvas';
// //import 'bootstrap/js/dist/scrollspy';
// //import 'bootstrap/js/dist/tab';
// //import 'bootstrap/js/dist/toast';

import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/regular";
import "@fortawesome/fontawesome-free/js/brands";

require('slick-carousel');
require("./content.js");
require("./helpers.js");
require("./recaptcha.js");
require("./cookie-consent.js");
require("./privacy.js");
require("./popup.js");
require("./form.js");

import "./../sass/app.sass";
